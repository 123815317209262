import {allDimensionFilters, allDimensions, allMetrics} from "@/utils/macros/google-analytics";

export default {
    computed: {
        getDonationPageOSCharSettings() {
            return {
                dimensions: [
                    {
                        name: allDimensions.customEvent.link
                    },
                    {
                        name: allDimensions.operatingSystem
                    },
                ],
                dimensionFilter: {
                    filter: {
                        fieldName: allDimensions.customEvent.link,
                        stringFilter: {
                            matchType: allDimensionFilters.matchType.string.contains,
                            value: this.getCurrentCollection.link
                        }
                    }
                },
                metrics: [
                    {
                        name: allMetrics.activeUsers,
                    }
                ]
            }
        }
    }
}