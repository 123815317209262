<template>
  <v-card class="ma-0 px-0 py-2 mt-4">
    <v-row class="px-4 pt-2 pb-1" justify="space-between" align="center">
      <v-col cols="5" class="py-1">
        <span>{{ texts.uid }}:</span>

      </v-col>
      <v-col cols="7" align="right" class="py-1">
        {{ getUID }}
        <v-icon @click="copyToClipboard(getUID)">
          mdi-content-copy
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="px-4 pt-2 pb-1" justify="space-between" align="center">
      <v-col cols="6" class="py-1">
        <span>{{ texts.displayName }}:</span>

      </v-col>
      <v-col cols="6" align="right" class="py-1">
        <v-row class="pa-0 ma-0" justify="end">
          <v-col cols="9" sm="11" md="8" xl="6" class="text-truncate pa-0">
            {{ getDisplayName }}
          </v-col>
          <v-col cols="3" sm="1" align="right" class="pa-0">
            <v-icon @click="copyToClipboard(getDisplayName)">
              mdi-content-copy
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-4 py-1" justify="space-between" align="center">
      <v-col cols="8" class="py-1">
        <span>{{ texts.paymentProvider}}:</span>

      </v-col>
      <v-col cols="4" align="right" class="py-1">
        <v-img
            v-if="getPaymentOperator"
            :width="providerImg.width"
            :src="getImageUrl"
            contain
        />
      </v-col>
    </v-row>
    <v-row class="px-4 py-1" justify="space-between" align="center">
      <v-col cols="5" class="py-1">
        <span>{{ texts.link }}:</span>

      </v-col>
      <v-col cols="7" align="right" class="py-1">
        {{ getCollectionLink }}
        <v-icon @click="openCollection" class="mr-1">
          mdi-open-in-new
        </v-icon>
        <v-icon @click="copyToClipboard(getCollectionLink)">
          mdi-content-copy
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="px-4 py-1" justify="space-between" align="center">
      <v-col cols="5" class="py-1">
        <span>{{ texts.customLink }}:</span>

      </v-col>
      <v-col cols="7" align="right" class="py-1">
        {{ getCollectionCustomLink }}
        <v-icon @click="copyToClipboard(getCollectionCustomLink)">
          mdi-content-copy
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="px-4 pt-1 pb-2" justify="space-between" align="center">
      <v-col cols="5" class="py-1">
        <span>{{ texts.stickers }}:</span>
      </v-col>
      <v-col cols="7" align="right" class="py-1">
        <span>{{ getStickersAmount }}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ProfileDetails',
  data: () => ({
    providerImg: {
      width: 50
    },
    texts: {
      uid: 'uid',
      displayName: 'Display name',
      paymentProvider: 'Payment provider',
      link: 'Collection link',
      customLink: 'Custom link',
      stickers: 'Stickers',
    }
  }),
  computed: {
    ...mapGetters('statistics', ['getCurrentCollection']),
    getCollectionCustomLink() {
      return this.getCurrentCollection?.customLink
    },
    getCollectionLink() {
      return this.$route.params.link
    },
    getImageUrl() {
      return require(`@/assets/img/${this.getPaymentOperator}.png`)
    },
    getPaymentOperator() {
      return this.getCurrentCollection?.paymentOperator
    },
    getStickersAmount() {
      return this.getCurrentCollection?.tags.length
    },
    getDisplayName() {
      return this.getCurrentCollection?.displayName
    },
    getUID() {
      return this.getCurrentCollection?.uid
    },
  },
  methods: {
    copyToClipboard(value) {
      try {
        navigator.clipboard.writeText(value)
      } catch(_) {
        document.execCommand('copy')
      }
    },
    openCollection() {
      window.open(`${process.env.VUE_APP_WEBSITE_URL}/${this.getCollectionLink}`, '_blank');
    },
  }
}
</script>
