<template>
  <div>
    <v-card class="mt-4 pb-4" v-if="isCollectedMoney">
      <v-card-subtitle class="font-weight-bold">
        {{ revenueChartTitle }}
      </v-card-subtitle>

      <v-row justify="center" align="center">
        <v-col cols="4">
          <date-picker
              @change-date="(e) => changeDate('startDate', e)"
              :title="datePickers.startDateTitle"
              :init-date="datePickers.startDate"
              :max-date="datePickers.endDate"
          >
          </date-picker>
        </v-col>
        <v-col cols="4">
          <date-picker
              @change-date="(e) => changeDate('endDate', e)"
              :title="datePickers.endDateTitle"
              :init-date="datePickers.endDate"
          >
          </date-picker>
        </v-col>
      </v-row>

      <div v-if="isFilledData(dailyTransactionsData).length">
        <v-tabs v-model="activeTab" class="pl-4" :centered="true" show-arrows>
          <v-tab class="text-none">{{ titles.dailyRevenue }}</v-tab>
          <v-tab class="text-none">{{ titles.dailyTransactions }}</v-tab>
          <v-tab class="text-none">{{ titles.dailyTransactionsCount }}</v-tab>
          <v-tab
              :disabled="avgPaymentTimeChartData.length === 1"
              class="text-none">
            {{ titles.avgPaymentTime }}
          </v-tab>
        </v-tabs>

        <v-row v-if="isRevenueTab" justify="space-between" align="center" class="ma-0 pa-0 mt-4">
          <v-col cols="1"></v-col> <!-- Justify -->
          <v-col cols="8">
            <v-row align="center">
              <v-card-subtitle v-if="$vuetify.breakpoint.smAndUp">
                {{ titles.sliderHeader }}
              </v-card-subtitle>
              <v-sheet class="pa-0">
                <v-slide-group
                    v-model="revenueCurrency"
                    show-arrows
                >
                  <v-slide-item
                      v-for="(element, index) in getCurrenciesOptions"
                      :key="index"
                      v-slot="{ active }"
                  ><!-- For now is PLN only handled -->
                    <v-btn
                        :input-value="active"
                        class="mx-2"
                        :color="active ? $mainColor : 'grey lighten-1'"
                        depressed
                        rounded
                        outlined
                        @click="revenueCurrency = index"
                    >
                      {{ element }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-row justify="center" align="center">
              <tooltip :message="titles.revenueTooltip"></tooltip> <!-- INTENTED DUPLICATE-->
            </v-row>
          </v-col>
          <v-col cols="1"></v-col> <!-- Justify -->
        </v-row>

        <v-row class="pa-0 ma-0 mt-4" v-if="isTransactionalTab || isTransactionalHoursTab">
          <v-col cols="9"></v-col><!-- Justify -->
          <v-col cols="2">
            <v-row justify="center">
              <tooltip
                  :message="titles[isTransactionalTab ? 'transactionsCountTooltip' : 'transactionsCountHoursTooltip']"></tooltip>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col> <!-- Justify -->
        </v-row>

        <average-payment-time-chart
            v-if="isAvgPaymentTimeTab"
            :chart-type="avgPaymentTimePickerType"
            :data="avgPaymentTimeChartData"
            hide-dates
        />

        <g-chart v-if="isTransactionalTab"
                 :type="lineChart"
                 :options="lineChartOptions"
                 :data="dailyTransactionsData"
        >
        </g-chart>

        <div v-for="(element, index) in getCurrenciesOptions" :key="index">
          <g-chart v-if="isRevenueTab && revenueCurrency === index"
                   :type="lineChart"
                   :options="lineChartOptions"
                   :data="dailyRevenueData[element]"
          >
          </g-chart>
        </div>

        <g-chart v-if="isTransactionalHoursTab"
                 :type="columnChart"
                 :options="verticalColumnChartOptions"
                 :data="transactionalHoursChartData"
        >
        </g-chart>
      </div>
      <v-row v-else class="ma-0 pa-0" justify="center">
        <v-chip>{{ titles.noData }}</v-chip>
      </v-row>
    </v-card>


    <component-with-measurement-date
        v-if="blueMediaProcessChartSettings.values.length"
        :date="blueMediaProcessMeasurementDate"
        class="mt-4"
    >
      <v-card-subtitle class="font-weight-bold">{{ titles.paymentsProcess }}</v-card-subtitle>
      <v-row justify="center" class="pa-0 ma-0">
        <funnel-chart-carousel
            :carousel-items="[blueMediaProcessChartSettings]"
        ></funnel-chart-carousel>
      </v-row>
    </component-with-measurement-date>

    <component-with-measurement-date
        v-if="usedAmountsInPaymentsData.length"
        :date="measurementDates[pieChartsTab]"
        class="mt-4"
    >
      <v-card-subtitle class="font-weight-bold">{{ titles.pieChartSection }}</v-card-subtitle>
      <v-tabs v-model="pieChartsTab" class="pl-4" :centered="true">
        <v-tab class="text-none">{{ titles.paidAmountsPieTitle }}</v-tab>
        <v-tab class="text-none" :disabled="!isFilledData(amountsTypesChartData).length">{{
            titles.typesAmountsPieTitle
          }}
        </v-tab>
        <v-tab :disabled="isDataEmpty(osChartData)" class="text-none">{{ titles.osChartsSection }}</v-tab>
      </v-tabs>

      <v-row class="pa-0 ma-0">
        <v-col cols="10">
          <v-row justify="end">
            <tooltip
                :message="pieChartsTooltips"></tooltip>
          </v-row>
        </v-col>

        <v-col v-if="isOSTypeTab" class="ma-0 pa-0">
          <v-row justify="center">
            <g-chart class="mb-4 mt-4"
                     :type="pieChart"
                     :data="osChartData"
                     :options="pieChartOptions"
            >
            </g-chart>
          </v-row>
        </v-col>

        <v-row v-if="isPaidAmountTab" justify="center">
          <v-col v-for="(element, index) in usedAmountsInPaymentsData" :key="index" class="pa-0 ma-0">
            <v-row justify="center" class="pa-0 ma-0" v-if="isPaidAmountTab">
              <g-chart class="mb-4 mt-4"
                       :type="pieChart"
                       :data="element"
                       :options="pieChartOptions"
              >
              </g-chart>
            </v-row>
          </v-col>
        </v-row>

        <v-col v-if="isAmountTypesChartVisible" class="pa-0 ma-0">
          <v-row justify="center">
            <g-chart class="mb-4 mt-4"
                     :type="pieChart"
                     :data="amountsTypesChartData"
                     :options="pieChartOptions"
            >
            </g-chart>
          </v-row>
        </v-col>
      </v-row>
    </component-with-measurement-date>

    <slot name="second-section"></slot>
    <v-card class="mt-4" v-if="tagsInCurrentCollection">
      <v-card-subtitle class="font-weight-bold">{{ titles.tagsTransactions }}</v-card-subtitle>
      <slot name="stickers"></slot>
      <g-chart
          :type="columnChart"
          :data="tagsTransactionsData"
          :options="verticalColumnChartOptions"
      >
      </g-chart>
    </v-card>

  </div>
</template>

<script>
import {
  googleChartTypes,
  chartTitles,
  collectionsTypes,
  currencies,
  tooltips,
  hoursObject,
  amountTypes,
  amountsTypesHeaders,
  amountsTypesImplementationDate,
  osChartHeaders,
  blueMediaPaymentsProcessChartHeaders,
  paymentOperatorsTitles, avgPaymentTimeHeaders, dateRangeType
} from '@/utils/macros/variables'
import externalChartsMixin from '@/mixins/components/external-charts'
import currentCollectionMixin from '@/mixins/components/current-collection'
import connectedChartsMixin from '@/mixins/components/connected-charts'
import DatePicker from "@/components/dashboard/collection-evidence/DatePicker"
import Tooltip from "@/components/dashboard/Tooltip";
import ga from '@/services/google-analytics'
import {mapActions, mapGetters, mapMutations} from "vuex";
import donationPageOsChart from "@/mixins/google-analytics/donation-page-os-chart";
import FunnelChartCarousel from "@/components/dashboard/FunnelChartCarousel";
import ComponentWithMeasurementDate from "@/components/dashboard/ComponentWithMeasurementDate";
import AveragePaymentTimeChart from "@/components/dashboard/charts/AveragePaymentTimeChart";
import AveragePaymentTimeChartMixin from "@/mixins/components/average-payment-time-chart";

export default {
  name: "Charts",
  mixins: [
    externalChartsMixin,
    currentCollectionMixin,
    connectedChartsMixin,
    donationPageOsChart,
    AveragePaymentTimeChartMixin
  ],
  components: {
    AveragePaymentTimeChart,
    DatePicker,
    Tooltip,
    FunnelChartCarousel,
    ComponentWithMeasurementDate
  },
  data: () => ({
    hoursObject,
    currencies,
    ...googleChartTypes,
    collectionsTypes,
    buttonInOptionWidth: 200,
    revenueCurrency: 0,
    blueMediaProcessMeasurementDate: '09-02-2022',
    measurementDates: { // INDEXED BY TAB CONTROLLERS
      1: '26-01-2022',
      2: '03-02-2022',
    },
    titles: {
      paymentsProcess: 'Stages of payments for payment operators with redirection',
      amountsTypesImplementationDate,
      pieChartSection: 'Pie charts section',
      osChartsSection: 'Operating Systems',
      paidAmountsPieTitle: 'Paid amounts',
      typesAmountsPieTitle: 'Donation button vs input',
      amountsTypes: 'Donation button clicks vs Donation input',
      sliderHeader: 'For currency:',
      noData: 'No data',
      osChartTooltip: 'Operating systems used by visitors',
      amountChart: 'Paid amounts all time',
      dailyTransactions: 'All transactions per days',
      dailyTransactionsCount: 'All transactions count per hours',
      dailyRevenue: 'Revenue',
      revenueTooltip: tooltips.collectionRevenueChart,
      transactionsCountTooltip: tooltips.collectionTransactionsCountChart,
      transactionsCountHoursTooltip: tooltips.collectionTransactionsHoursCountChart,
      tagsTransactions: 'All transactions [Stickers only]',
      dailyRevenueWithStickers: `${chartTitles.dailyRevenueChart} [Stickers + global collection]`,
      dailyRevenueNoStickers: `${chartTitles.dailyRevenueChart} [Global collection]`,
      avgPaymentTime: 'Average payment time'
    },
    transactionsChartHeaders: ['Tag No', 'Success only', 'Other statuses'],
    dailyTransactionsChartHeaders: ['Date', 'Success only', 'Other statuses'],
    dailyRevenueChartHeaders: ['Date', 'Revenue'],
    amountsPaidHeaders: ['Amount', 'Count'],
    activeTab: 0,
    pieChartsTab: 0,
    tagsTransactionsData: [],
    dailyTransactionsData: [],
    dailyRevenueData: [],
    usedAmountsInPaymentsData: [],
    amountsTypesChartData: [amountsTypesHeaders],
    osChartData: [osChartHeaders],
    funnelChart: {
      labels: blueMediaPaymentsProcessChartHeaders,
      colors: ['rgb(51, 102, 204)'],
      values: []
    },
    avgPaymentTimeChartData: [avgPaymentTimeHeaders],
    avgPaymentTimePickerType: dateRangeType.date,
  }),
  async beforeMount() {
    this.prepareTransactionalData()
    this.prepareDailyTransactionalData()
    this.prepareDailyRevenueData()
    this.prepareAmountChartData()
    this.prepareHoursTransactionalData()
    await this.prepareOSData()
    await this.fetchAndPrepareAmountsTypesData()
    this.prepareBlueMediaDonationProcessChart(this.getCurrentCollection.blueMediaDonationProcess)
    this.prepareAvgPaymentTimeChartData()
  },
  computed: {
    ...mapGetters('statistics', ['getCollectionsStatistics', 'getCurrentCollection']),
    blueMediaProcessChartSettings() {
      return this.funnelChart.values ? {
        ...this.funnelChartOptions,
        ...this.funnelChart,
        operator: paymentOperatorsTitles.blueMedia
      } : []
    },
    isAmountTypesChartVisible() {
      return this.isAmountsTypeTab && this.isFilledData(this.amountsTypesChartData).length
    },
    pieChartsTooltips() {
      if (this.isPaidAmountTab) {
        return `${this.titles.amountChart} ${this.getCurrentCollection.tags.length ? this.collectionsTypes.all : this.collectionsTypes.global}`
      }
      if (this.isOSTypeTab) {
        return this.titles.osChartTooltip
      }
      return this.titles.amountsTypes
    },
    getCurrenciesOptions() {
      return Object.keys(this.dailyRevenueData).filter(currency => !!this.isFilledData(this.dailyRevenueData[currency]).length)
    },
    isRevenueTab() {
      return this.activeTab === 0
    },
    isTransactionalTab() {
      return this.activeTab === 1
    },
    isTransactionalHoursTab() {
      return this.activeTab === 2
    },
    isAvgPaymentTimeTab() {
      return this.activeTab === 3
    },
    isPaidAmountTab() {
      return this.pieChartsTab === 0
    },
    isAmountsTypeTab() {
      return this.pieChartsTab === 1
    },
    isOSTypeTab() {
      return this.pieChartsTab === 2
    },
    revenueChartTitle() {
      return this.tagsInCurrentCollection ? this.titles.dailyRevenueWithStickers : this.titles.dailyRevenueNoStickers
    },
    tagsInCurrentCollection() {
      return Object.keys(this.getCurrentCollection?.tags || {}).length
    },
    getRenderTransactions() {
      return this.getCurrentCollection?.dailyTransactions || []
    }
  },
  methods: {
    ...mapMutations('statistics', ['addToCollectionsStatistics']),
    ...mapActions('statistics', ['collectionAmountsTypes']),
    changeDate(key, value) {
      this.datePickers[key] = value // FROM MIXIN
      this.prepareDailyRevenueData()
      this.prepareDailyTransactionalData()
      this.prepareHoursTransactionalData()
      this.prepareAvgPaymentTimeChartData()
    },
    async fetchAndPrepareAmountsTypesData() {
      const {id, link} = this.getCurrentCollection || {}

      if (id && !this.getCollectionsStatistics[link]) {
        await this.collectionAmountsTypes({id, link})
      }
      this.prepareAmountsTypesData(link)
    },
    prepareAmountsTypesData(link) {
      const {amountsTypes} = this.getCollectionsStatistics[link] || {}
      this.amountsTypesChartData = amountsTypes ? [amountsTypesHeaders, ...Object.entries(amountsTypes)
          .map(([key, value]) => [amountTypes[key], value])] : []
    },
    prepareTransactionalData() {
      this.tagsTransactionsData = this.getCurrentCollection?.tags?.length ? [
        this.transactionsChartHeaders, // FIRST ELEMENT OF DATA MUST BE HEADERS FOR CHART
        ...this.getCurrentCollection.tags
            .map(({transactions, id}) => [`No. ${id}`, transactions.success, transactions.other]
            ) // MAPPING DATA FOR STICKERS TRANSACTIONS CHART => NUMBER OF STICKER, SUCCESS TRANSACTIONS AMOUNT, OTHER TRANSACTIONS AMOUNT
            .filter(([, successTransactions, otherTransactions]) => successTransactions || otherTransactions) // FILTERING DATA, IF SUCCESS AND OTHER TRANSACTIONS - 0, DELETE FROM CHART
            .sort(([, aSuccessTransactions], [, bSuccessTransactions]) => this.$numberSortDesc(aSuccessTransactions, bSuccessTransactions)) // SORTING DESCENDING BY SUCCESS TRANSACTIONS
      ] : []
    },
    prepareDailyRevenueData() {
      const dailyTransactions = this.filterDates(this.getRenderTransactions)
      const results = {}

      Object.keys(dailyTransactions).forEach((element, index) => { // ITERATING ON OBJECT WHERE KEYS ARE DATES - GETTING KEY / DATE
        Object.keys(dailyTransactions[element].revenue).forEach(currency => { // FOR SPECIFIC DATE ITERATING ON REVENUE OBJECT WHERE KEYS ARE CURRENCIES AND VALUES ARE AMOUNTS
          index || (() => { // IF FIRST ELEMENT - CREATE CHART DATA FOR CURRENT CURRENCY
            results[currency] = [this.dailyRevenueChartHeaders] // FIRST ELEMENT IN DATA MUST BE HEADER
          })()

          const value = dailyTransactions[element].revenue[currency]
          typeof value !== 'object' && results[currency].push([element, value]) // PUSHING TO ARRAY DATA [DATE, AMOUNT FOR CURRENT CURRENCY]
        })
      })

      this.dailyRevenueData = results
    },
    prepareHoursTransactionalData() {
      const dailyTransactions = this.filterDates(this.getRenderTransactions)
      const data = {...this.hoursObject}

      Object.keys(dailyTransactions).forEach(date =>
          Object.entries(dailyTransactions[date].hoursInTransactions).forEach(([key, value]) => {
            data[key] += value
          }))

      this.setTransactionalHoursChartData(data)
    },
    prepareDailyTransactionalData() {
      const dailyTransactions = this.filterDates(this.getRenderTransactions)
      const results = Object.keys(dailyTransactions)
          .map(element => [element, dailyTransactions[element].success, dailyTransactions[element].other]) // MAPPING DAILY TRANSACTIONS DATA TO [DATA, SUCCESS TRANSACTIONS AMOUNT, OTHER TRANSACTIONS AMOUNT]
      this.dailyTransactionsData = [this.dailyTransactionsChartHeaders, ...results]
    },
    prepareAmountChartData() {
      const amountsInTransactions = this.getCurrentCollection?.amountsInTransactions || {}
      Object.keys(amountsInTransactions).forEach(currency => { // ITERATING ON OBJECT WHERE KEYS ARE CURRENCIES
        const currentCurrencyAmounts = Object.entries(amountsInTransactions[currency]) // FOR CURRENT CURRENCY CREATING ARRAY OF [AMOUNT OF PAID, COUNT OF AMOUNT OF PAID WAS USED]
        currentCurrencyAmounts.length && this.usedAmountsInPaymentsData.push([
          this.amountsPaidHeaders, // FIRST ELEMENT MUST BE HEADERS IN CHART DATA
          ...currentCurrencyAmounts.map(([amount, count]) => {
            return [`${amount} ${currency}`, count] // MAPPING DATA TO FORMAT [CURRENCY AMOUNT, 5] - E.G [PLN 5, 15]
          })
        ])
      })
    },
    prepareAvgPaymentTimeChartData() {
      const data = this.filterAvgPaymentTimeDates(this.getCurrentCollection?.avgPaymentTime, this.datePickers)

      this.avgPaymentTimeChartData = this.prepareAveragePaymentTimeData(data, this.datePickers)
    },
    async prepareOSData() {
      const data = await ga.getAnalyticsData.call(
          this,
          this.getDonationPageOSCharSettings
      )

      this.osChartData = [
        osChartHeaders,
        ...data.rows.map(({dimensionValues: [, os], metricValues: [amount]}) => [
          os.value,
          parseInt(amount.value)
        ])
      ]
    }
  },
  watch: {
    async getCurrentCollection() {
      this.prepareTransactionalData()
      this.prepareDailyTransactionalData()
      this.prepareDailyRevenueData()
      this.prepareAmountChartData()
      this.prepareHoursTransactionalData()
      await this.fetchAndPrepareAmountsTypesData()
      this.prepareBlueMediaDonationProcessChart(this.getCurrentCollection.blueMediaDonationProcess)
    }
  }
}
</script>
