export default {
    computed: {
        isCollectedMoney() {
            const data = this.getCurrentCollection?.balances || []
            return data
                .reduce((acc, {amount}) => {
                    return acc + amount
                }, 0)
        },
    }
}
