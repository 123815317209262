<template>
  <layout-dashboard :title="title">
    <v-container fluid class="pa-0 ma-0 fill-container-height">
      <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="10" class="pa-0 ma-0">
          <v-card-title class="pa-0 mt-4 mb-4">
            <router-link to="/dashboard/statistics">
              <v-btn class="text-none white--text" outlined :color="$mainColor">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                {{ texts.backButton }}
              </v-btn>
            </router-link>
          </v-card-title>

          <profile-details
            :texts="texts"
          />

          <v-card class="ma-0 pa-0 mt-4 px-4">
            <v-card-subtitle>
              <v-row class="font-weight-bold">
                <span>{{ texts.profit }}</span>
                <v-col v-for="({currency, amount}, index) in getBalances" :key="index" class="pa-1"
                       cols="12">
                  <v-row justify="space-between" class="px-2 py-4">
                    <span>{{ currency.toUpperCase() }}:</span>
                    <span>{{ amount.toLocaleString() }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-card>


          <collection-evidence-charts class="mt-4">
            <template #second-section>
              <v-card class="ma-0 pa-0 mt-4" v-if="stickersExist">
                <v-card-subtitle class="font-weight-bold">{{ texts.allTransactions }}</v-card-subtitle>
                <v-data-table :headers="[noStickerHeaders[0], noStickerHeaders[1], allItem]" :items="noStickersItems"
                              :items-per-page="5" hide-default-footer>
                  <template #item.other>{{ globalTransactions.other }}</template>
                  <template #item.success>{{ globalTransactions.success }}</template>
                  <template #item.all>{{ globalTransactions.other + globalTransactions.success }}</template>
                </v-data-table>
              </v-card>

              <v-card :class="['ma-0', 'pa-0', 'mt-4', stickersExist ? null : 'mb-4']">
                <v-card-subtitle class="font-weight-bold">{{ texts.main }}</v-card-subtitle>
                <v-data-table :headers="noStickerHeaders" :items="noStickersItems" :items-per-page="5"
                              hide-default-footer>
                  <template #item.other>{{ noStickersAmountTotal }}</template>
                  <template #item.success>{{ noStickersAmountSuccess }}</template>
                  <template #item.all>{{ noStickersAmountTotal + noStickersAmountSuccess }}</template>
                  <template #item.collected>
                    <v-row v-for="({currency, amount}, index) in getNoTagsProfit"
                           :key="index" justify="space-between" class="mx-0 my-2 px-0">
                      <div>
                        <span>{{ currency.toUpperCase() }}:&nbsp;</span>
                        <span>{{ amount.toLocaleString() }}</span>
                      </div>
                    </v-row>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template #stickers>
              <v-card-subtitle class="font-weight-bold">
                <v-row justify="center">
                  <v-col cols="12" md="3">
                    <v-row :justify="justifyHandler" class="pl-4">
                      {{ noStickerHeaders[0].text }}: {{ stickersTransactions.success }}
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row :justify="justifyHandler" class="pl-4">
                      {{ noStickerHeaders[1].text }}: {{ stickersTransactions.other }}
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row :justify="justifyHandler" class="pl-4">
                      {{ noStickerHeaders[2].text }}: {{ stickersTransactions.success + stickersTransactions.other }}
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </template>
          </collection-evidence-charts>

          <v-card class="mt-4 mb-4" v-if="stickersExist">
            <v-card-subtitle class="font-weight-bold">{{ texts.stickers }}</v-card-subtitle>
            <v-data-table
                :headers="stickerHeaders"
                :items="getCurrentCollection.tags"
                :items-per-page="5"
                :custom-sort="customSort"
            >
              <template #item.collected="{item}">
                <v-row
                    v-for="(element, index) in tagProfitArray(item).length ? tagProfitArray(item): defaultTagProfitArray"
                    :key="index" justify="space-between" class="mx-0 my-2 px-0">
                  <div>
                    <span>{{ element.toUpperCase() }}{{ item.amount ? ':' : '' }}&nbsp;</span>
                    <span>{{ tagCurrentAmount(item, element) }}</span>
                  </div>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </layout-dashboard>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import CollectionEvidenceCharts from '@/components/dashboard/collection-evidence/Charts'
import currentCollectionMixin from '@/mixins/components/current-collection'
import ProfileDetails from "@/components/dashboard/collection-evidence/ProfileDetails";
import DataTableMixin from "@/mixins/components/data-tables"
export default {
  name: "CollectionEvidence",
  components: {
    ProfileDetails,
    CollectionEvidenceCharts
  },
  mixins: [currentCollectionMixin, DataTableMixin],
  data: () => ({
    allItem: {text: '[Other + Success]', value: 'all', sortable: false},
    noStickersItems: [{}], // INTENTED
    title: 'Collection evidence',
    withoutStickers: [],
    defaultTagProfitArray: [''],
    loadingMessage: 'Fetching collection data',
    texts: {
      profit: 'Total revenue',
      main: 'All transactions [Global collection]',
      allTransactions: 'All transactions [Stickers + global collection]',
      backButton: 'Back',
      noStickers: 'No stickers',
      stickers: 'Stickers evidence',
      operator: 'Payment operator'
    },
    stickerHeaders: [
      {text: 'Id', value: 'id', sortable: false},
      {text: 'Success only transactions', value: 'transactions.success', sortable: true},
      {text: 'Other statuses transactions', value: 'transactions.other', sortable: true},
      {text: 'Revenue', value: 'collected', sortable: true},
    ],
    noStickerHeaders: [
      {text: 'Success only', value: 'success', sortable: false},
      {text: 'Other statuses', value: 'other', sortable: false},
      {text: '[Other + Success]', value: 'all', sortable: false},
      {text: 'Revenue', value: 'collected', sortable: false},
    ]
  }),
  computed: {
    ...mapGetters('statistics', ['getAppStatistics', 'getCollectionsStatistics', 'getCurrentCollection']),
    getNoTagsProfit() {
      return this.getCurrentCollection?.noTagsProfit || []
    },
    getBalances() {
      return this.getCurrentCollection?.balances || []
    },
    justifyHandler() {
      return `${this.$vuetify.breakpoint.smAndDown ? 'start' : 'center'}`
    },
    noStickersAmountTotal() {
      return this.getCurrentCollection?.transactionsAmount?.byGlobalCollection.other || 0
    },
    noStickersAmountSuccess() {
      return this.getCurrentCollection?.transactionsAmount?.byGlobalCollection.success || 0
    },
    stickersExist() {
      return this.getCurrentCollection?.tags?.length
    },
    globalTransactions() {
      return this.getCurrentCollection?.transactionsAmount?.allTransactions || 0
    },
    stickersTransactions() {
      return this.getCurrentCollection?.transactionsAmount?.byStickers || 0
    },
  },
  beforeMount() {
    this.setLoader({
      visible: !this.getCurrentCollection,
      message: this.loadingMessage
    })
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    tagCurrentAmount(item, currency) {
      return item?.amount?.[currency] || 0
    },
    tagProfitArray(item) {
      return Object.keys(this.profitCalculate(item))
    },
    profitCalculate(item) {
      return item?.amount || {}
    }
  },
  watch: {
    getCurrentCollection(value) {
      this.setLoader({visible: !value, message: this.loadingMessage})
    }
  }
}
</script>
