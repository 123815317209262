var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-dashboard',{attrs:{"title":_vm.title}},[_c('v-container',{staticClass:"pa-0 ma-0 fill-container-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"pa-0 mt-4 mb-4"},[_c('router-link',{attrs:{"to":"/dashboard/statistics"}},[_c('v-btn',{staticClass:"text-none white--text",attrs:{"outlined":"","color":_vm.$mainColor}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(_vm.texts.backButton)+" ")],1)],1)],1),_c('profile-details',{attrs:{"texts":_vm.texts}}),_c('v-card',{staticClass:"ma-0 pa-0 mt-4 px-4"},[_c('v-card-subtitle',[_c('v-row',{staticClass:"font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.texts.profit))]),_vm._l((_vm.getBalances),function(ref,index){
var currency = ref.currency;
var amount = ref.amount;
return _c('v-col',{key:index,staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"px-2 py-4",attrs:{"justify":"space-between"}},[_c('span',[_vm._v(_vm._s(currency.toUpperCase())+":")]),_c('span',[_vm._v(_vm._s(amount.toLocaleString()))])])],1)})],2)],1)],1),_c('collection-evidence-charts',{staticClass:"mt-4",scopedSlots:_vm._u([{key:"second-section",fn:function(){return [(_vm.stickersExist)?_c('v-card',{staticClass:"ma-0 pa-0 mt-4"},[_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.texts.allTransactions))]),_c('v-data-table',{attrs:{"headers":[_vm.noStickerHeaders[0], _vm.noStickerHeaders[1], _vm.allItem],"items":_vm.noStickersItems,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.other",fn:function(){return [_vm._v(_vm._s(_vm.globalTransactions.other))]},proxy:true},{key:"item.success",fn:function(){return [_vm._v(_vm._s(_vm.globalTransactions.success))]},proxy:true},{key:"item.all",fn:function(){return [_vm._v(_vm._s(_vm.globalTransactions.other + _vm.globalTransactions.success))]},proxy:true}],null,false,2739333382)})],1):_vm._e(),_c('v-card',{class:['ma-0', 'pa-0', 'mt-4', _vm.stickersExist ? null : 'mb-4']},[_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.texts.main))]),_c('v-data-table',{attrs:{"headers":_vm.noStickerHeaders,"items":_vm.noStickersItems,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.other",fn:function(){return [_vm._v(_vm._s(_vm.noStickersAmountTotal))]},proxy:true},{key:"item.success",fn:function(){return [_vm._v(_vm._s(_vm.noStickersAmountSuccess))]},proxy:true},{key:"item.all",fn:function(){return [_vm._v(_vm._s(_vm.noStickersAmountTotal + _vm.noStickersAmountSuccess))]},proxy:true},{key:"item.collected",fn:function(){return _vm._l((_vm.getNoTagsProfit),function(ref,index){
var currency = ref.currency;
var amount = ref.amount;
return _c('v-row',{key:index,staticClass:"mx-0 my-2 px-0",attrs:{"justify":"space-between"}},[_c('div',[_c('span',[_vm._v(_vm._s(currency.toUpperCase())+": ")]),_c('span',[_vm._v(_vm._s(amount.toLocaleString()))])])])})},proxy:true}])})],1)]},proxy:true},{key:"stickers",fn:function(){return [_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"pl-4",attrs:{"justify":_vm.justifyHandler}},[_vm._v(" "+_vm._s(_vm.noStickerHeaders[0].text)+": "+_vm._s(_vm.stickersTransactions.success)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"pl-4",attrs:{"justify":_vm.justifyHandler}},[_vm._v(" "+_vm._s(_vm.noStickerHeaders[1].text)+": "+_vm._s(_vm.stickersTransactions.other)+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"pl-4",attrs:{"justify":_vm.justifyHandler}},[_vm._v(" "+_vm._s(_vm.noStickerHeaders[2].text)+": "+_vm._s(_vm.stickersTransactions.success + _vm.stickersTransactions.other)+" ")])],1)],1)],1)]},proxy:true}])}),(_vm.stickersExist)?_c('v-card',{staticClass:"mt-4 mb-4"},[_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.texts.stickers))]),_c('v-data-table',{attrs:{"headers":_vm.stickerHeaders,"items":_vm.getCurrentCollection.tags,"items-per-page":5,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.collected",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.tagProfitArray(item).length ? _vm.tagProfitArray(item): _vm.defaultTagProfitArray),function(element,index){return _c('v-row',{key:index,staticClass:"mx-0 my-2 px-0",attrs:{"justify":"space-between"}},[_c('div',[_c('span',[_vm._v(_vm._s(element.toUpperCase())+_vm._s(item.amount ? ':' : '')+" ")]),_c('span',[_vm._v(_vm._s(_vm.tagCurrentAmount(item, element)))])])])})}}],null,false,763687827)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }